import { Fab, Grid, Typography } from "@material-ui/core"
import { Edit as EditIcon } from "@material-ui/icons"
import { DateTime } from "luxon"
import React from "react"
import ReactMaterialComponentBase from "../../Shared/ReactMaterialComponentBase"
import { ViewMealPlan as ReactViewMealPlan } from "../React/ViewMealPlan"
import s1 from "react-grid-layout/css/styles.css"

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
const meals = ["Lunch", "Tea"]

export default class ViewMealPlans extends ReactMaterialComponentBase {
  constructor() {
    super()

    /** @type {Array<import("../Types").MealPlan>} */
    this.mealPlans = []

    this.jsxRootComponent = () => (
      <Grid container spacing={2}>
        {this.mealPlans.map((mealPlan, index) => {
          return (
            <>
              <Grid item xs={12}>
                <Typography variant="h4">{`Week Commencing ${DateTime.fromISO(mealPlan.startDate).toFormat(
                  "DD"
                )}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <ReactViewMealPlan key={index} editable={false} mealPlan={mealPlan} days={days} meals={meals} />
              </Grid>
              <Grid item xs={12}>
                <Fab
                  color="primary"
                  onClick={() => {
                    this.dispatchEvent(
                      new CustomEvent("edit-mealplan", { detail: mealPlan, bubbles: true, composed: true })
                    )
                  }}
                >
                  <EditIcon />
                </Fab>
              </Grid>
            </>
          )
        })}
      </Grid>
    )
  }

  connectedCallback() {
    super.connectedCallback() && super.connectedCallback()

    const style = document.createElement("style")
    style.type = "text/css"
    style.appendChild(document.createTextNode(s1))

    this.shadow.appendChild(style)

    this.getMealPlans()
  }

  async getMealPlans() {
    const target = this.getAttribute("target")

    try {
      const token = await window.authorise()

      const mealPlansResponse = await fetch(target, {
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      })

      const mealPlans = await mealPlansResponse.json()

      this.mealPlans = mealPlans

      this.render()
    } catch (error) {
      this.onError && this.onError(error)
    }
  }
}

customElements.define("view-meal-plans", ViewMealPlans)
